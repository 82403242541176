import * as React from 'react'
import { graphql, Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import AddToCart from '../components/shop/AddToCart'
import formatCurrency from '../utils/formatCurrency'
import ShopLayout from '../layouts/Shop'
import ProductList from '../components/shop/ProductList'

interface CategoryTemplateProps {
  data: {
    producersJson: {
      name: string
      slug: string
      location: string
      homepage: string
    }
    allProductsJson: {
      edges: Array<{
        node: {
          name: string
          slug: string
          price: number
        }
      }>
    }
  }
}
const ProducerTemplate: React.FC<CategoryTemplateProps> = ({ data }) => {
  const products = data.allProductsJson.edges.map(p => p.node)
  return (
    <ShopLayout>
      <Page>
        <Container>
          <h1>{data.producersJson.name}</h1>
          <p>aus {data.producersJson.location}</p>
          {data.producersJson.homepage && (
            <p>
              Mehr Informationen: <a href={data.producersJson.homepage}>{data.producersJson.homepage}</a>
            </p>
          )}
          <ProductList products={products} />
        </Container>
      </Page>
    </ShopLayout>
  )
}

export default ProducerTemplate

export const query = graphql`
  query ProducerTemplateQuery($slug: String!) {
    producersJson(slug: { eq: $slug }) {
      name
      slug
      location
      homepage
    }
    allProductsJson(filter: { producer: { eq: $slug } }) {
      edges {
        node {
          ...ProductList
        }
      }
    }
  }
`
